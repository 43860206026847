(function () {
    'use strict';

    /**
     * Registers our service worker.
     *
     * @param {URL} url The service worker URL.
     * @param {object} config Optional config.
     */
    async function registerWorker(url, config) {
        console.log('Registering service worker.');

        let registration = await navigator.serviceWorker.register(url, { scope: '/', 'type': 'module' });

        registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
                return;
            }

            installingWorker.onstatechange = () => {
                if (installingWorker.state !== "installed") {
                    return;
                }

                if (navigator.serviceWorker.controller) {
                    // At this point, the updated pre-cached content has been fetched,
                    // but the previous service worker will still serve the older content until all client tabs are closed.
                    console.log("New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.");

                    // Execute callback
                    if (config && config.onUpdate) {
                        config.onUpdate(registration);
                    }
                } else {
                    // At this point, everything has been pre-cached. It's the perfect time to display a "Content is cached for offline use." message.
                    console.log("Content is cached for offline use.");

                    // Execute callback
                    if (config && config.onSuccess) {
                        config.onSuccess(registration);
                    }
                }
            };
        };
    }

    ((config) => {
        console.log('Checking service worker support.');

        if (!("serviceWorker" in navigator)) {
            console.log('Service worker not supported.');

            return;
        }

        console.log('Service worker supported.');

        let swUrl = new URL('../worker.js', import.meta.url);

        try {
            registerWorker(swUrl, config);
        } catch (error) {
            console.error("Error during service worker registration:", error);
        }
    })();
})();